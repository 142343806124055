const useMetaDataUpdater = () => {
  const updateMetaData = (subDomain: string, faviconUrl: string) => {
    // Extract subdomain

    // Set document title
    document.title = `${subDomain}`;

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', `Welcome to ${subDomain}`);
    } else {
      // If the meta description tag doesn't exist, create one
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = `Welcome to ${subDomain}`;
      document.head.appendChild(newMetaDescription);
    }

    // Remove any existing favicons
    const existingIcons = document.querySelectorAll('link[rel="icon"]');
    existingIcons.forEach((icon) => {
      if (icon?.parentNode) {
        icon.parentNode.removeChild(icon);
      }
    });

    // Add the new favicon
    const newLinkIcon = document.createElement('link');
    newLinkIcon.rel = 'icon';
    newLinkIcon.href = faviconUrl;
    document.head.appendChild(newLinkIcon);
  };

  return { updateMetaData };
};

export default useMetaDataUpdater;
