export const FACEBOOK_BASE_URL = 'https://www.facebook.com';

export const WA_EMBEDDED_SIGNUP = 'WA_EMBEDDED_SIGNUP';

export const FACEBOOK_APP_ID = '1307717409721266';

export const FACEBOOK_APP_ID_PINNACLE = '1175295590370734';

export const FACEBOOK_SDK_VERSION = 'v18.0';

export const FACEBOOK_CONFIG_ID = '934003244810969';

export const PINNACLE_SOLUTION_ID = '833621515503453';

export const FACEBOOK_CONFIG_ID_PINNACLE = '529272623005963';
