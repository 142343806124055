import { ChannelPartnerFeatureFlag } from '../../../../shared/typings/request';
import { NextScreenState } from '../../../../shared/typings/screens';

export interface ChannelPartnerDetails {
  id: string;
  name: string;
  supportNumber: string;
  ownerId: string;
  logoUrl: string;
  domain: string;
  isOwner: boolean;
  privacyLink: string;
  tearOfServiceLink: string;
  recapthcaSiteKey: string;
  firebaseApiKey: string;
  featureFlags: ChannelPartnerFeatureFlag;
}
export interface VerifyOtpResponse {
  organizations: Array<Organization>;
  token: string;
  user: {
    countryCode: string;
    isOnWhatsapp: boolean;
    phone: string;
    isNewUser?: boolean;
  };
  nextScreenState: NextScreenState;
  channelPartnerDetails: ChannelPartnerDetails | null;
}

export interface Organization {
  orgId: string;
  name: string;
  domain: string | null;
  pictureUrl: string | null;
  isIntegrationEnabled: boolean;
  inviteId?: string | null;
  isDemo?: boolean;
  isOwner: boolean;
  wabaPhoneNumber?: number;
}

export interface UserSignupDetails {
  firstName: string;
  lastName: string;
  orgName: string;
  designation: string;
  websiteUrl?: string;
  email?: string;
  isEmailVerified?: boolean;
  isWebsiteRequired: boolean;
}

export interface UserEmail {
  email: string;
  isVerified: boolean;
}

export interface AddEmailResponse {
  emailCode: string;
}

export interface VerifyEmailResponse {
  orgId: string;
}

export interface VerifyValidWhatsapp {
  phone: string;
  isValid: boolean;
}

export interface PhoneNumber {
  countryCode: string;
  countrySymbol: string;
  countryId: string;
  phone: string;
}

export enum UserAction {
  SEND_AND_RECEIVE_MESSAGES = 'sendAndReceiveMessages',
  SEND_AND_RECEIVE_PRODUCTS = 'sendAndReceiveProducts',
}

export interface ReceiveTestMessage {
  message: string;
  wabaPhoneNumber: string;
}

export enum OTPServices {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export enum TechProviderProgress {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
