import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { trackEvent, trackLogout } from '../../../infra/analytics/utils';
import { firebaseSignInWithCustomToken } from '../../../infra/auth/firebase-auth';
import {
  deleteMessagingToken,
  firebaseAuth,
} from '../../../infra/firebase/init';
import { ROUTES } from '../../../shared/constants/routes';
import { useSetAuth } from '../../../shared/hooks/use-auth';
import { useSetProfile } from '../../../shared/hooks/use-profile';
import {
  useOrganizations,
  useSetSelectedOrganization,
} from '../../../shared/hooks/use-organizations';
import { LoginStep } from '../../../shared/states/login';
import { ChatType } from '../../home/v1/components/chat-list-section/chat-row/typings';
import { externalSelectedChat } from '../../home/v1/states/selected-chat';
import chooseOrganisation from '../../login/v1/requests/choose-organisation';
import { LoginStepAtom } from '../../login/v1/state';
import { MiniMessage, MiniMessageTypes } from '../typings';
import { detailsPanelAtom } from '../../home/v1/states/details';
import { nextScreenStateToRouteMap } from '../../../shared/typings/screens';
import getRefreshNextState from '../../../shared/modules/demo-account/v1/requests/refresh-next-state';
import getUserDetails from '../../home/v1/requests/get-user-details';
import { CustomFieldsTypes } from '../../home/v1/typings';
import { fetchedCacheForListAtom } from '../../home/v1/states/customer-details';
import useConversationNavigate, {
  useCustomNavigate,
} from '../../../shared/hooks/use-conversation-navigate';
import { postMessageToIframe } from '../../../shared/utils/post-message';
import { importCustomersModalVisibleAtom } from '../../customers/state';
import { userStatusAtom } from '../../../shared/states/user';
import { UserStatusData } from '../../../shared/typings/user';
import { USER_STATUS_STORAGE_KEYS } from '../../../shared/constants/storage';
import {
  broadcastFrequencyCappingSettingsMapAtom,
  channelMembersInfoSetter,
} from '../../home/v1/states/chat';
import {
  autoRetrySettingsModalVisibleAtom,
  selectedBroadcastIdAtom,
} from '../../../shared/states/template';
import { logout } from '../../login/v1/requests/logout';

const useMiniListeners = () => {
  const navigate = useCustomNavigate();

  const setUserProfile = useSetProfile();
  const { fetchOrganizations } = useOrganizations();
  const setSelectedOrganization = useSetSelectedOrganization();
  const { navigateToConversation } = useConversationNavigate();

  const setAuthState = useSetAuth();

  const setLoginStep = useSetAtom(LoginStepAtom);
  const setSelectedChat = useSetAtom(externalSelectedChat);
  const setDetailsPanel = useSetAtom(detailsPanelAtom);
  const setFetchedListsCache = useSetAtom(fetchedCacheForListAtom);
  const setImportCustomersModalVisible = useSetAtom(
    importCustomersModalVisibleAtom
  );
  const setAutoRetrySettingsModalVisible = useSetAtom(
    autoRetrySettingsModalVisibleAtom
  );
  const setSelectedBroadcastId = useSetAtom(selectedBroadcastIdAtom);
  const setUserStatus = useSetAtom(userStatusAtom);
  const setChannelMembersInfo = useSetAtom(channelMembersInfoSetter);
  const [
    broadcastFrequencyCappingSettingsMap,
    setBroadcastFrequencyCappingSettingsMap,
  ] = useAtom(broadcastFrequencyCappingSettingsMapAtom);

  const handleSwitchOrg = useCallback(
    async (orgId: string) => {
      const { user, nextScreenState } = await chooseOrganisation(orgId);
      await firebaseSignInWithCustomToken(user.token);
      const org = await fetchOrganizations();
      setSelectedOrganization(org[0]);
      setAuthState({
        isAuthReady: true,
        isCheckingAuth: false,
        isOrgSelected: true,
        isIntegrationEnabled: true,
        isPermissionsReady: true,
        makePayment: false,
      });
      setLoginStep(LoginStep.PHONE);

      if (nextScreenState) {
        navigate({ pathname: nextScreenStateToRouteMap[nextScreenState] });
      } else {
        navigate(
          { pathname: ROUTES.DASHBOARD.replace('/*', '/') },
          { replace: true }
        );
      }
    },
    [
      fetchOrganizations,
      navigate,
      setAuthState,
      setLoginStep,
      setSelectedOrganization,
    ]
  );

  const onBillingDetailsSubmitted = useCallback(async () => {
    const data = await getRefreshNextState();
    if (data.nextScreenState) {
      navigate({ pathname: nextScreenStateToRouteMap[data.nextScreenState] });
    } else {
      navigate({ pathname: ROUTES.LOGIN.replace('/*', '/') });
    }
  }, []);

  const handleChangeCustomField = useCallback(
    async (customFieldId: string, type?: CustomFieldsTypes) => {
      if (type === CustomFieldsTypes.LIST) {
        setFetchedListsCache(false);
      }
    },
    []
  );

  const handleContactSupport = (message: string) => {
    const supportPhone = '917977814605';
    const encodedMessage = encodeURIComponent(
      message.length > 0 ? message : 'I want to learn more about DoubleTick'
    );
    const link = `https://wa.me/${supportPhone}?text=${encodedMessage}`;
    window.open(link, ' _blank');
  };

  useEffect(() => {
    const messageHandler = async (message: MessageEvent<MiniMessage>) => {
      const { data } = message;
      switch (data.type) {
        case MiniMessageTypes.OPEN_GROUP_CHAT: {
          //FIXME: WABA
          setSelectedChat({
            id: data?.data?.groupChatId || '',
            unreadCount: 0,
            assignedUserId: null,
            assignedUserName: null,
            chatType: ChatType.GROUP,
            chatTypeId: data.data?.groupId || '',
            name: data?.data?.groupChatName || '',
            canManageGroupAccess: data?.data?.canManageGroupAccess || false,
            groupAccessLevel: data?.data?.groupAccessLevel,
            phoneNumber: '',
            tags: [],
            imageUrl: '',
            integrationId: '',
            integrationWabaNumber: '',
            integrationWabaPhoneName: '',
            integrationDisplayName: '',
            isSlaActive: false,
            slaDueDate: '',
          });
          break;
        }
        case MiniMessageTypes.TRACK_EVENT: {
          if (typeof data.data?.eventName !== 'string') {
            return;
          }
          trackEvent(data.data.eventName, data.data?.properties);
          break;
        }
        case MiniMessageTypes.DELETE_CUSTOM_FIELD:
        case MiniMessageTypes.UPDATE_CUSTOM_FIELD:
        case MiniMessageTypes.CREATE_CUSTOM_FIELD: {
          await handleChangeCustomField(data.data.id, data.data.type);
          break;
        }
        case MiniMessageTypes.GO_BACK: {
          navigate(-1);
          break;
        }
        case MiniMessageTypes.OPEN_CUSTOMER_CHAT: {
          setSelectedChat(data.data);
          break;
        }
        case MiniMessageTypes.SWITCH_ORG: {
          if (typeof data.data?.orgId !== 'string') {
            return;
          }
          await handleSwitchOrg(data.data.orgId);
          break;
        }
        case MiniMessageTypes.CONTACT_SUPPORT: {
          handleContactSupport(data.data?.message);
          break;
        }
        case MiniMessageTypes.BILLING_DETAILS_SUBMITTED: {
          await onBillingDetailsSubmitted();
          break;
        }
        case MiniMessageTypes.REDIRECT: {
          navigate({ href: data.data.url });
          break;
        }

        case MiniMessageTypes.OPEN_RENEWAL_BLOCKER: {
          navigate({ pathname: ROUTES.RENEW_PLAN });
          break;
        }
        case MiniMessageTypes.SEND_INDIVIDUAL_MESSAGE: {
          if (
            typeof data.data?.phoneNumber !== 'string' &&
            typeof data.data?.customerId !== 'string'
          ) {
            return;
          }

          if (!data.data.integrationId || !data.data.integrationWabaNumber) {
            const openWabaSelectorEvent = new CustomEvent('openWabaSelector', {
              detail: data.data,
            });
            document.dispatchEvent(openWabaSelectorEvent);
            break;
          }
          setDetailsPanel({ open: false });
          navigateToConversation({
            phoneNumber: data.data.phoneNumber,
            chatTypeId: data.data.customerId,
            waba: data.data.integrationWabaNumber,
          });
          break;
        }
        case MiniMessageTypes.ADD_GROUP_MEMBERS: {
          const addMemberEvent = new CustomEvent('openAddGroupMemberModal', {
            detail: data.data,
          });
          document.dispatchEvent(addMemberEvent);
          break;
        }
        case MiniMessageTypes.ADD_BROADCAST_SUCCESS: {
          const addToBroadcastEvent = new CustomEvent('addToBroadcast', {
            detail: data.data,
          });
          document.dispatchEvent(addToBroadcastEvent);
          break;
        }
        case MiniMessageTypes.GET_SELECTED_CUSTOMER_IDS: {
          const getSelectedCustomerIdsEvent = new CustomEvent(
            'getSelectedCustomerIds',
            {
              detail: data.data,
            }
          );
          document.dispatchEvent(getSelectedCustomerIdsEvent);
          break;
        }
        case MiniMessageTypes.CHANGE_CUSTOM_TOKEN: {
          if (typeof data.data?.token !== 'string') {
            return;
          }
          await firebaseSignInWithCustomToken(data.data.token);
          const profile = await getUserDetails(true);
          setUserProfile(profile);
          if (typeof data.data?.iframeId === 'string') {
            postMessageToIframe(
              data.data?.iframeId,
              JSON.stringify({
                type: 'CHANGE_CUSTOM_TOKEN',
                data: {
                  token: data.data.token,
                },
              })
            );
          }
          break;
        }
        case MiniMessageTypes.GET_CURRENT_PATH: {
          if (typeof data.data?.iframeId !== 'string') {
            return;
          }
          postMessageToIframe(
            data.data?.iframeId,
            JSON.stringify({
              type: 'GET_CURRENT_PATH',
              data: {
                href: window.location.href,
                pathname: window.location.pathname,
                search: window.location.search,
                hash: window.location.hash,
                host: window.location.host,
                protocol: window.location.protocol,
              },
            })
          );
          break;
        }
        case MiniMessageTypes.OPEN_IMPORT_EXCEL_MODAL: {
          setImportCustomersModalVisible(true);
          break;
        }
        case MiniMessageTypes.OPEN_AUTO_RETRY_SETTINGS_MODAL: {
          const freqCappingSettings = broadcastFrequencyCappingSettingsMap.get(
            data.data.broadcastId
          );
          if (!freqCappingSettings) return;

          if (freqCappingSettings.endDate) {
            const currentDate = new Date();
            const endDate = new Date(freqCappingSettings.endDate);
            if (endDate < currentDate) return;
          }

          setAutoRetrySettingsModalVisible(true);
          setSelectedBroadcastId(data.data.broadcastId);
          break;
        }
        case MiniMessageTypes.SET_AUTO_RETRY: {
          setBroadcastFrequencyCappingSettingsMap((prevMap) => {
            const tmpMap = new Map(prevMap);
            const updatedSettings = tmpMap.get(data.data.broadcastId);
            if (updatedSettings) {
              tmpMap.set(data.data.broadcastId, {
                ...updatedSettings,
                state: data.data.state,
              });
            }
            return tmpMap;
          });
          break;
        }
        case MiniMessageTypes.SET_USER_STATUS: {
          setUserStatus((prev) => ({
            ...prev,
            data: {
              ...(prev.data as UserStatusData),
              status: data.data.status,
              statusExpiration: data.data.statusExpiration,
            },
          }));
          localStorage.setItem(
            USER_STATUS_STORAGE_KEYS.LAST_OFFLINE_SYNCED_TIME,
            new Date().toISOString()
          );
          break;
        }
        case MiniMessageTypes.SEND_CHANNEL_MEMBERS_INFO: {
          setChannelMembersInfo({
            groupId: data.data.groupId,
            memberCount: data.data.memberCount,
            showMemberCount: data.data.showMemberCount,
          });
          break;
        }
        case MiniMessageTypes.LOGOUT: {
          try {
            await logout();
            await deleteMessagingToken();
          } catch (error) {
            return;
          }
          await firebaseAuth.signOut();
          trackLogout();
          setLoginStep(LoginStep.PHONE);
          navigate(
            { pathname: ROUTES.LOGIN.replace('/*', '') },
            { replace: true }
          );
          setUserProfile(undefined);
          break;
        }
        default: {
          break;
        }
      }
    };
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [
    setSelectedChat,
    handleChangeCustomField,
    navigate,
    handleSwitchOrg,
    onBillingDetailsSubmitted,
    setDetailsPanel,
    navigateToConversation,
    setUserProfile,
    setImportCustomersModalVisible,
    setChannelMembersInfo,
    setLoginStep,
    setBroadcastFrequencyCappingSettingsMap,
  ]);
};

export default useMiniListeners;
