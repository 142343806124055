import { ChannelPartnerDetails } from '../typings';

const CHANNEL_PARTNER_DETAILS = 'CHANNEL_PARTNER_DETAILS';

export function getUserChannelData(): ChannelPartnerDetails | null {
  try {
    const storedValue = localStorage.getItem(CHANNEL_PARTNER_DETAILS);
    return storedValue ? JSON.parse(storedValue) : null;
  } catch (e) {
    return null;
  }
}

export function shouldHideDTDetails(): boolean {
  const partnerChannelData = getUserChannelData();
  return !!partnerChannelData?.name;
}

export function updateUserChannelData(
  config: Partial<ChannelPartnerDetails>
): void {
  const partnerChannelData = getUserChannelData();
  if (!partnerChannelData) {
    setUserChannelData({
      id: '',
      name: '',
      supportNumber: '',
      ownerId: '',
      logoUrl: '',
      domain: '',
      tearOfServiceLink: '',
      privacyLink: '',
      featureFlags: {},
      isOwner: false,
      recapthcaSiteKey: '',
      firebaseApiKey: '',
      ...config,
    });
  } else {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          registration?.active?.postMessage({
            type: 'INIT_FIREBASE',
            config: {
              apiKey: partnerChannelData.firebaseApiKey,
            },
          });
        });
    }
    setUserChannelData({ ...partnerChannelData, ...config });
  }
}

export function setUserChannelData(
  channelData: ChannelPartnerDetails | null
): void {
  if (channelData) {
    localStorage.setItem(CHANNEL_PARTNER_DETAILS, JSON.stringify(channelData));
  } else {
    localStorage.removeItem(CHANNEL_PARTNER_DETAILS);
  }
}
