function checkIfChannelPartner(): boolean {
  const forbiddenKeywords = ['doubletick', 'quicksell', 'localhost'];
  const host = window.location.host;
  for (const keyword of forbiddenKeywords) {
    if (host.includes(keyword)) {
      return false;
    }
  }

  return true;
}

export default checkIfChannelPartner;
