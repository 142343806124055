import { deleteApp, FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import {
  getMessaging,
  getToken,
  deleteToken,
  isSupported,
  Messaging,
} from 'firebase/messaging';
import config from '../../shared/config/firebase';
import appConfig from '../../shared/config/app';

export let firebaseApp = initializeApp(config);
export let firebaseAuth = getAuth(firebaseApp);
export let messaging = getMessaging();

export const initializeFirebaseApp = async (apiKey: string) => {
  let newConfig = {
    ...config,
  };
  if (apiKey && config.apiKey === apiKey) {
    return firebaseApp;
  }
  if (apiKey) {
    newConfig.apiKey = apiKey;
  }
  try {
    // If an app already exists, delete it first
    const existingApp = getApp();
    if (existingApp) {
      await deleteApp(existingApp);
    }
  } catch (error) {
    // If no app exists, ignore the error (since it's not initialized yet)
  }
  firebaseApp = initializeApp(newConfig);
  firebaseAuth = getAuth(firebaseApp);
  messaging = getMessaging(firebaseApp);
  return firebaseApp;
};

export const getCurrentUserId = () => firebaseAuth?.currentUser?.uid;

export const getMessagingToken = (registration?: ServiceWorkerRegistration) => {
  return getToken(messaging, {
    vapidKey: appConfig.firebaseVapidKey,
    serviceWorkerRegistration: registration,
  });
};

export const isFirebaseMessagingSupported = async () => {
  return isSupported();
};

export const deleteMessagingToken = () => {
  return deleteToken(messaging);
};
