import {
  deleteMessagingToken,
  firebaseAuth,
} from '../../../../infra/firebase/init';
import { trackLogout } from '../../../../infra/analytics/utils';
import { logout } from '../requests/logout';

export const logoutUseCase = async () => {
  try {
    await logout();
    await deleteMessagingToken();
  } catch (error) {
    throw error;
  }
  await firebaseAuth.signOut();
  trackLogout();
};
