import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import './index.css';
import './utils.css';
import theme from './theme';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';
import App from './modules/app/components/App';
import { initAnalytics } from './infra/analytics/init';
import * as Sentry from '@sentry/react';
import ErrorContainer from './shared/components/molecules/error';
import appConfig from './shared/config/app';
import { NotificationsProvider } from './shared/components/molecules/notifications-provider';
import { register } from './serviceWorkerRegistration';

initAnalytics();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: appConfig.sentryDsn,
    enabled: false,
    beforeSend(event, hint) {
      if (
        event.message?.includes('ResizeObserver') ||
        event.exception?.values?.[0]?.type?.includes('ResizeObserver') ||
        event.exception?.values?.[0]?.type ===
          'ResizeObserver loop limit exceeded'
      ) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      'ResizeObserver',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorContainer error={error.message || 'Something went wrong'} />
      )}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <NotificationsProvider maxNotifications={5} />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
);
// register();
