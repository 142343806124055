/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ChatType } from '../../components/chat-list-section/chat-row/typings';
import Avatar from '@mui/material/Avatar';
import GroupIcon from '../../../../../shared/icons/GroupIcon';
import { getColorFromPhone } from '../../utils/colors';
import { CSSProperties, useMemo } from 'react';
import { chatRowAvatarSize, chatRowAvatarStyles } from '../../constants';

export function ChatProfileAvatar({
  chatType,
  phoneNumber = '',
  name = '',
  profilePicture,
  styles,
}: {
  chatType?: ChatType;
  phoneNumber?: string;
  name?: string;
  profilePicture?: string;
  styles?: CSSProperties;
}) {
  const displayName = useMemo(
    () =>
      (name ?? phoneNumber ?? '')
        .replace(/[\u200B-\u200D\uFEFF]/g, '')
        .trim()
        ?.charAt(0)
        .toUpperCase() ?? '0',
    [name, phoneNumber]
  );

  if (!chatType) {
    return null;
  }

  if (chatType === ChatType.GROUP) {
    return (
      <Avatar
        style={styles}
        css={css`
          background-color: #c1d6d0;
          width: ${chatRowAvatarSize}px;
          height: ${chatRowAvatarSize}px;
          margin-right: 4px;
        `}
      >
        <GroupIcon fill="#688F84" />
      </Avatar>
    );
  }

  const color = getColorFromPhone({
    phone: phoneNumber,
    chatType,
    name,
  });

  if (profilePicture) {
    return (
      <Avatar
        style={styles}
        src={profilePicture}
        alt={displayName}
        css={css(
          chatRowAvatarStyles,
          css`
            background: ${color}32;
            color: ${color};
            font-size: 1rem;
            font-weight: 600;
            text-transform: capitalize;
            width: ${chatRowAvatarSize}px;
            height: ${chatRowAvatarSize}px;
          `
        )}
      />
    );
  }

  return (
    <div
      css={css(
        chatRowAvatarStyles,
        css`
          background: ${color}32;
          color: ${color};
          font-weight: 600;
          text-transform: capitalize;
        `
      )}
      style={styles}
    >
      {displayName}
    </div>
  );
}
