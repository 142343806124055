import { io, Socket } from 'socket.io-client';
import requestConfig from '../../shared/config/request';
import { socketOptions } from './index';
import { SocketStatus } from '../../shared/typings';

let organizationsSocket: Socket;

export const getOrganizationsSocket = (): Socket => {
  if (organizationsSocket) {
    return organizationsSocket;
  }
  organizationsSocket = io(
    `${requestConfig.socketUrl}/organization`,
    socketOptions
  );

  organizationsSocket.on('connect', () => {
    document.dispatchEvent(new CustomEvent('organizations-socket-connect'));
    if (window.socketStatus) {
      window.socketStatus['organizations'] = {
        status: SocketStatus.CONNECTED,
        message: 'Connected successfully',
      };
    }
  });
  organizationsSocket.on('disconnect', (reason) => {
    if (window.socketStatus) {
      window.socketStatus['organizations'] = {
        status: SocketStatus.NOT_CONNECTED,
        message: reason || 'Unknown reason',
      };
    }
  });
  return organizationsSocket;
};
