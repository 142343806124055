/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import QSModal from '../modal';
import { QSButton, QSIconButton, QSTypography } from '../../atoms';
import { Close } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import useNotifications from '../../../hooks/use-notifications';
import Loader from '../loader';
import { useAtom } from 'jotai';
import { upgradePlanAtom } from '../../../../modules/home/v1/states/upgrade-plan';
import SLAIntegrationImg from '../../../assets/img/ChannelPartner/SLAIntegrate.webp';
import { openPopup } from '../../../utils/popup';
import ProgressiveImage from '../../atoms/progressive-image';
import { ReactComponent as ArrowUp } from '../../../assets/svg/ArrowUp.svg';

import { ReactComponent as Clock } from '../../../assets/svg/Clock.svg';

import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';

import { ReactComponent as UserShield } from '../../../assets/svg/UserShield.svg';

import { ReactComponent as Robot } from '../../../assets/svg/Robot.svg';

import { ReactComponent as SmileyHeart } from '../../../assets/svg/SmileyHeart.svg';
import {
  getUserChannelData,
  shouldHideDTDetails,
} from '../../../../modules/login/v1/utility';

const UpgradePlanModal = () => {
  const [loading, setLoading] = useState(false);
  const [upgradeModal, setUpgradeModal] = useAtom(upgradePlanAtom);
  const { addNotification } = useNotifications();
  const channelData = getUserChannelData();
  const ytVideoLink = 'https://www.youtube.com/watch?v=A6bUHstRaaw';
  const hideDTDetails = shouldHideDTDetails();
  let contactUsLink =
    'https://wa.me/918356849474?text=I%27m%20interested%20in%20DoubleTick%20Enterprise';
  if (hideDTDetails) {
    contactUsLink = `https://wa.me/${channelData?.supportNumber}?text=I%27m%20interested%20in%20Enterprise%20Plan`;
  }

  const onClose = useCallback(() => {
    setUpgradeModal({
      isModalActive: false,
    });
  }, [setUpgradeModal]);

  const onClickWatchDemo = useCallback(async () => {
    try {
      setLoading(true);
      openPopup(ytVideoLink, '_blank');
      onClose();
    } catch (error) {
      addNotification({
        message: (error as Error).message ?? 'An error occurred.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [addNotification, onClose]);

  const onClickContactUs = useCallback(async () => {
    try {
      setLoading(true);
      openPopup(contactUsLink, '_blank');
      onClose();
    } catch (error) {
      addNotification({
        message:
          (error as Error).message ?? 'An error occurred. Please try again',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [addNotification, onClose]);

  const orgName = channelData?.name ?? 'DoubleTick';

  if (!upgradeModal.isModalActive) {
    return null;
  }

  return (
    <QSModal
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      open={upgradeModal.isModalActive}
      onClose={onClose}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          overflow: hidden;
          background: white;
          outline: none;
          position: relative;
          padding: 16px;
          max-width: 700px;
        `}
      >
        <QSIconButton
          css={css`
            position: absolute;
            top: 8px;
            right: 8px;
          `}
          sx={{}}
          onClick={onClose}
        >
          <Close />
        </QSIconButton>
        <div
          css={css`
            flex: 1;
            overflow: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1;
              background-color: #f6f1e7;
              border-radius: 8px;
              overflow: hidden;
            `}
          >
            {/* <YTVideo
            ytVideoURL="https://www.youtube.com/watch?v=A6bUHstRaaw"
            cssString={css`
              width: 100%;
              height: 400px;
              object-fit: cover;
              border-radius: 8px;
            `}
            embeddedURL="https://www.youtube.com/embed/A6bUHstRaaw?si=_m7PeJz1WzRCNnNv"
          /> */}
            <ProgressiveImage
              imgProps={{ width: '100%', height: '400px' }}
              src={
                !hideDTDetails
                  ? 'https://catalogue-cdn.quicksell.co/doubletick/sla_file.webp'
                  : SLAIntegrationImg
              }
            />
          </div>
          <div
            css={css`
              flex: 1;
              padding: 0 16px;
            `}
          >
            <span
              css={css`
                border: 1px solid #e0e0e9;
                border-radius: 50px;
                padding: 4px 8px 4px 4px;
                display: inline-flex;
                align-items: center;
                font-size: 12px;
                margin-bottom: 16px;
                margin-top: 16px;
                font-weight: 500;
              `}
            >
              <ArrowUp />
              <span
                css={css`
                  margin-left: 5px;
                `}
              >
                Upgrade to {orgName} Enterprise
              </span>
            </span>
            <QSTypography
              css={css`
                font-weight: 600;
                padding-right: 12px;
                font-size: 20px;
                overflow: hidden;
                line-height: 28px;
                margin-bottom: 16px;
              `}
            >
              Set response timer for all customer chats{' '}
              <span
                css={css`
                  color: #7a7a7a;
                `}
              >
                {' '}
                with SLA (Service level agreement)
              </span>
            </QSTypography>

            {[
              {
                featureLabel: 'Set desired response time for your team',
                icon: <Clock />,
              },
              {
                featureLabel: 'Monitor breached conversations',
                icon: <Eye />,
              },
              {
                featureLabel: 'Increase team accountability',
                icon: <UserShield />,
              },

              {
                featureLabel: 'Trigger a bot if response time has passed',
                icon: <Robot />,
              },
              {
                featureLabel: 'Enhance customer satisfaction',
                icon: <SmileyHeart />,
              },
            ].map((feature, key) => (
              <div
                key={key}
                css={css`
                  display: inline-flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 12px;
                  margin-bottom: 5px;
                  font-weight: 500;
                `}
              >
                {feature?.icon}
                <span
                  css={css`
                    margin-left: 8px;
                  `}
                >
                  {feature?.featureLabel}
                </span>
              </div>
            ))}

            {!loading ? (
              <div
                css={css`
                  margin-top: 24px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <QSButton
                  css={css`
                    height: 40px;
                    width: 50%;
                  `}
                  onClick={onClickContactUs}
                >
                  Contact Us
                </QSButton>

                {/* <QSButton
                  variant="outlined"
                  css={css`
                    width: 50%;
                    display: flex;
                    height: 40px;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border: 1px solid #e0e0e9;
                    background: #fff;
                    color: inherit;
                    outline: none;
                    box-shadow: none;

                    &:hover {
                      background: #f5f5f8;
                      outline: none;
                      box-shadow: none;
                    }
                  `}
                  onClick={onClickWatchDemo}
                >
                  <PlayCircle
                    size={20}
                    css={css`
                      // margin-right: 8px;
                    `}
                  />{' '}
                  Watch Demo
                </QSButton> */}
              </div>
            ) : (
              <Loader secondary="" size={16} />
            )}
          </div>
        </div>
      </div>
    </QSModal>
  );
};

export default UpgradePlanModal;
